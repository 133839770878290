<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
           @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Pesanan</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a id="shareBtn" class="btn px-0 d-inline-block mr-3" @click="shareData">
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <router-link
              :to="'/jual/pesanan-group/edit/' + state.id"
              class="btn px-0 d-inline-block"
            >
              <i class="fas fa-pencil-alt text-light"></i>
            </router-link>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="
              'background-image: url(' +
              state.url +
              state.data.group.photo +
              ');'
            "
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <div id="details-accordion">

              <div class="w-100 text-center">
                <a class="collapsed" data-toggle="collapse" data-target="#collapse-details" aria-expanded="true" aria-controls="collapse-details">
                  <i class="fa" aria-hidden="true"></i>
                </a>
              </div>

              <div id="collapse-details" class="collapse" aria-labelledby="headingOne" data-parent="#details-accordion">
                <div class="row mt-3">
                  <div class="col-6">
                    <p class="mb-1 small text-muted">Jumlah Pcs / Maksimal</p>
                    <p class="mb-0 text-main">{{ state.jumlah }}/{{ state.data.max }} pcs </p>
                  </div>
                  <!-- .col -->
                  <div class="col-6 text-right text-md-left">
                    <p class="mb-1 small text-muted">Status</p>
                    <p class="mb-0">
                      <span
                        class="badge align-top"
                        :class="
                          state.data.status === 1 ? 'badge-secondary' : 'badge-danger'
                        "
                        >{{ state.data.status === 1 ? "AKTIF" : "INAKTIF" }}</span
                      >
                    </p>
                  </div>
                  <!-- .col -->
                </div>
                <!-- .row -->

                <div class="row mt-3">
                  <div class="col-6">
                    <p class="mb-1 small text-muted">Batas Pemesanan</p>
                    <p class="mb-0 small text-main">
                      {{ formatDate(state.data.batas_akhir) }}
                    </p>
                  </div>
                  <!-- .col -->
                  <div class="col-6 text-right text-md-left">
                    <p class="mb-1 small text-muted">Waktu Pengiriman</p>
                    <p class="mb-0 small text-main">
                      {{ formatDate(state.data.tanggal_pengiriman) }}
                    </p>
                  </div>
                  <!-- .col -->
                </div>
                <!-- .row -->

                <!-- Hanya muncul bila PO Grup via Reseller -->
                <div class="row mt-3 d-none">
                  <div class="col-6">
                    <p class="mb-1 small text-muted">Reseller</p>
                    <p class="mb-0">Mba Oi</p>
                  </div>
                  <!-- .col -->
                  <div class="col-6 text-right text-md-left">
                    <p class="mb-1 small text-muted">Lokasi</p>
                    <p class="mb-0">
                      <a href="" data-toggle="modal" data-target="#addrModal"
                        >Kb.Jeruk</a
                      >
                    </p>
                  </div>
                  <!-- .col -->
                </div>
                <!-- .row -->

                <!-- Hanya muncul bila ada catatan -->
                <div class="row mt-3 d-none">
                  <div class="col-12">
                    <p class="mb-1 small text-muted">Catatan</p>
                    <p class="mb-0"></p>
                  </div>
                  <!-- .col -->
                </div>
                <!-- .row -->
              </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 px-0">
              <ul class="list-group rounded-0 mt-3">
                <!-- <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <a
                    href="javascript:void(0);"
                    id="promoBtn"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Promosikan PO Group</a
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li> -->
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <router-link
                    :to="'/jual/pesanan/produk/' + state.id"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Daftar Produk yang Dipesan</router-link
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <a
                    href="javascript:void(0);"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    data-toggle="modal"
                    data-target="#inaktifModal"
                    title="Pesanan inaktif"
                    >Pesanan Inaktif</a
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
              </ul>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-3">
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Cari..."
                v-model="state.search" />
                <span class="input-group-append">
                    <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
                ></button>
                </span>
                </div>
            </div>
          </form>

          <div class="small text-truncate">
            Daftar Pesanan Aktif untuk {{ state.data.name }}:
          </div>
          <div v-if="state.exist">
            <div
              id="resultList"
              class="row mt-1"
              v-for="pesan in state.pesanan"
              :key="pesan.id"
              v-show="pesan.status"
            >
              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <div class="card mb-2">
                  <div class="card-body p-0">
                    <div class="row no-gutters">
                      <div class="col-9 border-right p-2 pl-3">
                        <router-link
                          :to="'/jual/pesanan/customer/' + pesan.id"
                          class="
                            card-title
                            text-main
                            font-weight-bold
                            d-block
                            mb-1
                            mt-1
                            card-link
                            stretched-link
                            text-truncate
                          "
                          >{{ pesan.pelanggan.prefix }}
                          {{ pesan.pelanggan.name }}</router-link
                        >
                        <p class="card-text mb-0 text-truncate">{{ pesan.pelanggan.group.name }},{{ pesan.pelanggan.blok_no }} {{ pesan.pelanggan.alamat }}</p>
                        <div v-html="sumData(pesan)"></div>
                      </div>
                      <div class="col-3 p-2 pl-2">
                        <div class="ml-1">
                          <form>
                            <div
                              class="custom-control custom-checkbox mt-1 mb-2"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :checked="pesan.siap"
                              />
                              <label
                                class="custom-control-label pt-1"
                                for="siap"
                                @click="changeStatus('siap', pesan.siap, pesan.id)"
                                >Siap?</label
                              >
                            </div>
                          </form>
                          <form>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :checked="pesan.terkirim"
                              />
                              <label
                                class="custom-control-label pt-1"
                                for="kirim"
                                @click="changeStatus('terkirim', pesan.terkirim, pesan.id)"
                                >Kirim?</label
                              >
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->

            <div class="row mb-5">
              <div class="col-12 text-center">
                <button class="btn btn-main btn-sm" :disabled="state.isSelesai" @click="updateSelesai">
                  <i class="fa fa-check-square-o mr-1"></i> SELESAI
                </button>
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>

          <router-link
            to="/jual/pesanan/tambah"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
          >
            <div class="d-flex justify-content-center mt-3">
              <i class="fas fa-cart-plus" style="font-size: 25px"></i>
            </div>
          </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->

    <div class="modal fade" id="inaktifModal">
      <div
        class="
          modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title h6">Pesanan Inaktif</div>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div class="small mb-3 text-truncate">Daftar Pesanan Inaktif:</div>
            <div
              class="card mb-2"
              v-for="pesan in state.pesanan"
              :key="pesan.id"
              v-show="!pesan.status"
            >
              <div class="card-body p-0">
                <div class="row no-gutters">
                  <div class="col-8 border-right p-2 pl-3">
                    <a
                      :href="'/jual/pesanan/customer/' + pesan.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ pesan.pelanggan.prefix }}
                      {{ pesan.pelanggan.name }}</a
                    >
                    <p class="card-text mb-0 text-truncate">{{ state.groupName }},{{ pesan.pelanggan.blok_no }} {{ pesan.pelanggan.alamat }}</p>
                    <div v-html="sumData(pesan)"></div>

                  </div>
                  <div class="col-4 p-2 pl-2">
                    <div class="ml-1">
                      <span class="badge badge-light">INAKTIF</span>
                      <form>
                        <div class="custom-control custom-checkbox mt-1 mb-2">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="'checkbox-'+pesan.id"
                          />
                          <label
                            class="custom-control-label pt-1"
                            for="customCheckM01"
                            @click="setAktif(pesan)"
                            >Aktifkan</label
                          >
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .card -->
          </div>
        </div>
      </div>
    </div>
    <!-- #inaktifModal -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import moment from "moment";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import Share from '../../../components/social_media/Share';

export default {
  setup() {
    const route = useRoute();
    const $swal = useVueSweetAlert2();
    const Toast = $swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    })
    const state = reactive({
      data: {},
      finish: false,
      id: "0",
      url: process.env.VUE_APP_API_URL,
      pesanan: {},
      originalPesanan: {},
      exist: false,
      search: '',
      jumlah: '~',
      groupName: '',
      isSelesai: true,
      valueFalse: 0,
    });
    const loadData = async () => {
      state.finish = false;
      let id = route.params.id;
      await axios.get("/api/seller/po-group/" + id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          if(state.data.group){
            state.groupName = state.data.group.name;
          }
          state.finish = true;
          if (res.data.data.pesanan.length > 0) {
            state.exist = true;
            state.pesanan = res.data.data.pesanan;
            state.originalPesanan = res.data.data.pesanan;
            let jumlah = 0;
            state.pesanan.map((pes) => {
              state.valueFalse += parseInt(pes.invoice);
              state.valueFalse += parseInt(pes.pembayaran)

              pes.detail.map((detail) => {
                jumlah += Number(detail.jumlah);
              });
            });
            state.jumlah = jumlah;
          }
          state.isSelesai = Boolean(res.data.data.selesai)
        }
      });
    };
    
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const formatDate = (dt) => {
      return moment(dt).format("DD/MM/YYYY");
    };
    const sumData = (dt) => {
      let pesanan = dt.detail.length;
      let jumlah = 0;
      let total = 0;
      dt.detail.map((detail) => {
        jumlah += Number(detail.jumlah);
        total += Number(detail.total);
      });
      let cur = Math.ceil(Number(total));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];

      return (
        '<p class="card-text mb-0 text-truncate">' +
        '<small class="text-muted">' +
        pesanan +
        " pesanan, " +
        jumlah +
        " pcs," +
        '<span class="currency"> Rp. ' +
        ret +
        "</span></small></p>"
      );
    };
    const searchData = () => {
        let result = [];
        const data = state.originalPesanan;
        Object.values(data).find((dt, index) => {
          if(dt.pelanggan.name.match(state.search) || dt.pelanggan.prefix.match(state.search)){
            result.push(data[index]);
          }
        })
        state.pesanan = result;
    }
    const setAktif = (dt) => {
      $swal
          .fire({
            text: "Apakah Anda Yakin?",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
          })
          .then((result) => {
            if (result.isConfirmed) {
              axios.get('/api/seller/pesanan/setActive/'+dt.id).then((res) => {
                if(res.data.success){
                  loadData();
                  $swal.fire({
                    icon: "success",
                    text: "Status Berhasil Dirubah!",
                  });
                }
              })
            }
        });
    }
    const changeStatus = (dt, value, id) => {
      let form = {
        status: dt,
        value: !value
      }
      let title = "Status reset!";
      if(!value === true){
        if(dt === 'siap'){
          title = 'Pesanan ini telah siap.';
        }else {
          title = 'Pesanan ini telah dikirim.';
        }
      }
      axios.post('/api/seller/pesanan/setStatus/'+id, form).then((res) => {
        if(res.data.success){
          loadData();
          Toast.fire({
            icon: 'success',
            title: title
          });
        }
      })
    }
    const shareData = () => {
      let dt = {
        title: state.data.name,
        url: process.env.VUE_APP_HOME_URL + '/home/po/'+state.data.key,
        description: '',
      }
      Share.run('whatsapp', dt);
    }
    const updateSelesai = () => {
      if(!state.isSelesai){
        axios.get('/api/seller/pesanan/setSelesai/'+state.id).then((res) => {
          if(res.data.success){
            loadData();
            Toast.fire({
              icon: 'success',
              title: 'Pesanan Selesai!'
            });
          }
        });
      }
    }
    const back = () => {
      window.history.back();
    }
    return {
      state,
      formatDate,
      sumData,
      searchData,
      setAktif,
      changeStatus,
      shareData,
      updateSelesai,
      back
    };
  },
};
</script>